<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <base-button type="success" @click="addNew" class="my--5">
                <i class="fas fa-plus"></i> {{$t('general.addnew')}}
            </base-button>
        </b-card>
        <b-row class="mt-4">
            <b-col md="3" v-for="(item, index) in videos" :key="index">
                <b-card>
                    <a :href="`https://www.youtube.com/watch?v=${item.id}`" target="_new">
                        <strong>{{item.title}}</strong>
                        <hr class="my-2">
                        <b-img :src="`https://img.youtube.com/vi/${item.id}/hqdefault.jpg`" fluid />
                    </a>
                    <b-button variant="danger" size="sm" class="mt-2" @click.prevent="handleDelete(index)">
                        <i class="fas fa-trash"></i> {{$t('general.delete')}}</b-button>
                </b-card>
            </b-col>
        </b-row>
        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modalClose">
            <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{$t('video.add')}}</h6>
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading">
                    <b-alert variant="info" show class="mt-3">
                        {{$t('video.add_info')}} <br><br> {{$t('video.add_sample')}} : https://www.youtube.com/watch?v=abcDEF123<br><br> {{$t('video.add_info_2')}}
                    </b-alert>
                    <base-input type="text" name="title" :nameAs="$t('video.title')" :label="$t('video.title')" :placeholder="$t('video.title')" v-model="modal.title" required>
                    </base-input>
                    <base-input type="text" name="link" :nameAs="$t('video.link')" :label="$t('video.link')" :placeholder="$t('video.link')" v-model="modal.link" required>
                    </base-input>
                    <b-alert show variant="warning" v-if="invalid">
                        <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid">{{$t('save')}}</base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                </b-form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { VIDEO_REQUEST, VIDEO_ADD, VIDEO_DELETE } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;

export default {
    computed: {
        ...mapGetters({ videos: 'getVideos' })
    },
    data() {
        return {
            modal: {
                loading: false,
                status: false,
                title: '',
                link: ''
            },
            loading: false
        };
    },
    methods: {
        addNew() {
            self.modal.loading = false;
            self.modal.status = true;
        },
        modalClose() {
            self.modal.loading = false;
            self.modal.status = false;
            self.modal.link = '';
        },
        handleDelete(index) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(VIDEO_DELETE, index)
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        onSubmit() {
            self.onAdd();
        },
        onAdd() {
            let link = _.cloneDeep(self.modal.link);
            let title = _.cloneDeep(self.modal.title.trim());
            self.modal.loading = true;
            self.$store
                .dispatch(VIDEO_ADD, { link, title })
                .then(() => {
                    self.modal.loading = false;
                    self.modal.status = false;
                    self.getData();
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(VIDEO_REQUEST)
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        }
    },
    created() {
        self = this;
        self.getData();
    }
};
</script>
